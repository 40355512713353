import React from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import ProductbarItem from "../ProductbarItem";
import ProductbarSubMenu from "../ProductbarSubMenu";
import ProductbarIconSubMenu from "../ProductbarIconSubMenu";

const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.white.contrast,
      width: "100%",
      padding: "0px 60px 0px 60px",
      borderBottom: "1px solid #CCCCCC"
    },

    content: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-end",
      padding: "0 60px 0 60px",
      margin: 0,
      padding: 0
      // position: "relative",
    },
    logo: {
      width: "180px",
      height: "auto",
      // justifySelf: "flex-start",
      marginRight: "auto",
      listStyleType: "none"
    }
  };
});

export default function ProductBarDesktop(props) {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <>
      <nav
        className={classes.root}
        aria-label="Mornington communications Main Navigation Menu"
      >
        <ul className={classes.content} role="menu">
          <li className={classes.logo} role="menuitem">
            <a href="/">
              <img
                src="/assets/images/mornington-colour.svg"
                alt="Mornington communications logo"
              />
            </a>
          </li>
          <ProductbarItem
            active={history.location.pathname === "/business"}
            {...props}
            onClick={function (e) {
              e.preventDefault();
              history.push("/business");
            }}
          >
            Home
          </ProductbarItem>

          <ProductbarItem
            active={history.location.pathname.includes("/business/shop")}
            dropDown
            sections={[
              {
                title: "Internet",
                links: [
                  {
                    content: "Fibre",
                    href: "/business/shop/internet/fibre",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/business/shop/internet/fibre");
                    }
                  },
                //   {
                //     content: "DSL",
                //     href: "/business/shop/internet/dsl",

                //     onClick: function (e) {
                //       e.preventDefault();
                //       history.push("/business/shop/internet/dsl");
                //     }
                //   },
                  {
                    content: "Circuits Internet",
                    href: "/business/shop/internet/circuits-internet",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push(
                        "/business/shop/internet/circuits-internet"
                      );
                    }
                  },
                  {
                    content: "Web Hosting",
                    href: "/business/shop/internet/web-hosting",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/business/shop/internet/web-hosting");
                    }
                  }
                ]
              },
              {
                title: "Telephone",
                links: [
                  {
                    content: "Services",
                    href: "/business/shop/telephone/services",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/business/shop/telephone/services");
                    }
                  }
                ]
              },
              {
                title: "Mobile",
                links: [
                  {
                    content: "Mobility Solutions",
                    href: "/business/shop/mobile/mobility-solutions",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push(
                        "/business/shop/mobile/mobility-solutions"
                      );
                    }
                  }
                ]
              },
              {
                title: "Business Security",
                links: [
                  {
                    content: "Services",
                    href: "/business/shop/business-security/services",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push(
                        "/business/shop/business-security/services"
                      );
                    }
                  },
                  {
                    content: "Installation",
                    href: "/business/shop/business-security/installation",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push(
                        "/business/shop/business-security/installation"
                      );
                    }
                  }
                ]
              },
            //  Removed business bundles Nov 2023 
            //  {
            //     title: "Bundles",
            //     links: [
            //       {
            //         content: "View bundles",
            //         href: "/business/shop/bundles/view-bundles",
            //         onClick: function (e) {
            //           e.preventDefault();
            //           history.push("/business/shop/bundles/view-bundles");
            //         }
            //       }
            //     ]
            //   }
            ]}
            {...props}
            megaMenu={true}
            Submenu={ProductbarSubMenu}
            caret
          >
            Shop
          </ProductbarItem>

          <ProductbarItem
            active={history.location.pathname.includes("/live-support")}
            onClick={function (e) {
              e.preventDefault();
              history.push("/contact-us/live-support");
            }}
            {...props}
          >
            Live Support
          </ProductbarItem>

          <ProductbarItem
             //active={"https://webmail.cyg.net"}
            onClick={function (e) {
              e.preventDefault();
              window.open('https://webmail.cyg.net','_blank');
            }}
            {...props}
          >
            Webmail <i className="fa fa-external-link-alt"></i>
          </ProductbarItem>

          <ProductbarItem
            dropDown
            caret
            sections={[
              {
                links: [
                  {
                    content: "Get in Touch",
                    href: "/contact-us/get-in-touch",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/get-in-touch");
                    }
                  },
                  {
                    content: "Our Locations",
                    href: "/contact-us/our-locations",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/our-locations");
                    }
                  },
                  {
                    content: "Live Support",
                    href: "/contact-us/live-support",
                    onClick: function (e) {
                      e.preventDefault();
                      history.push("/contact-us/live-support");
                    }
                  }
                ]
              }
            ]}
            {...props}
            Submenu={ProductbarSubMenu}
          >
            Contact Us{" "}
          </ProductbarItem>
          <ProductbarItem
            {...props}
            dropDown
            noHover
            Submenu={ProductbarIconSubMenu}
          >
            <i class="fa fa-search"></i>
          </ProductbarItem>

          <ProductbarItem
            {...props}
            dropDown
            noHover
            Submenu={ProductbarIconSubMenu}
          >
            <i class="fa fa-location-arrow"></i>{" "}
          </ProductbarItem>
        </ul>

        {/* <ProductbarIconSubMenu /> */}
      </nav>
    </>
  );
}
