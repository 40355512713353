import React, { useState, useEffect, useRef, useCallback } from "react";
import { createUseStyles } from "react-jss";
import PhoneGridSortList from "../PhoneGridSortList";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main,
      position: "relative",
      zIndex: "99",
      flexGrow: "1",
      userSelect: "none",
      zIndex: 20
    },
    button: {
      textAlign: "center",
      letterSpacing: "0",
      color: "#000000",
      opacity: "0.66",
      borderStyle: "none",
      backgroundColor: "#fff",
      font: "500 16px/19px Avenir",
      padding: "14px 16px 14px 16px",
      boxSizing: "border-box",
      whiteSpace: "nowrap",
      position: "relative",
      width: "100%",
      zIndex: 39
    },
    optionsWrapper: {
      position: "absolute",
      zIndex: 41
    },
    openbtn: {
      backgroundColor: theme.palette.gray.light
    },
    "@media (max-width: 700px)": {
      root: {
        width: "100%",
        textAlign: "left"
      },
      button: {
        textAlign: "left",
        "& i.fa-caret-up, & i.fa-caret-down": {
          float: "right"
        }
      },
      optionsWrapper: {
        width: "100%",
        position: "relative"
      }
    }
  };
});

function PhoneGridSortButton(props) {
  const classes = useStyles(props);
  const { options, ...rest } = props;
  const [isOpen, setOpen] = useState(false);

  const node = useRef(null);
  const main = useRef(null);

  const cb = useCallback(
    function handler(e) {
      if (node.current && node.current.contains(e.target)) {
        if (main.current && main.current.contains(e.target)) setOpen(!isOpen);
        return;
      }
      setOpen(false);
    },
    [isOpen]
  );

  useEffect(
    function() {
      document.addEventListener("click", cb, false);
      // document.addEventListener("keypress", cb, false);
      return function() {
        document.removeEventListener("click", cb, false);
        // document.addEventListener("keypress", cb, false);
      };
    },
    [isOpen]
  );
  return (
    <div className={classes.root} ref={node}>
      <button
        onClick={function() {
          setOpen(true);
        }}
        className={`${classes.button} ${isOpen ? classes.openbtn : ""}`}
        ref={main}
      >
        {props.children}{" "}
        {isOpen ? (
          <i class="fa fa-caret-up"></i>
        ) : (
          <i class="fa fa-caret-down"></i>
        )}
      </button>
      <div className={classes.optionsWrapper}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              style={{ overflow: "hidden" }}
            >
              <PhoneGridSortList options={options} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

PhoneGridSortButton.defaultProps = {
  checkbox: true,
  options: [
    { content: "tefdafdasfdsdaadsfast", type: "radio" },
    { content: "test", type: "radio", onClick: function() {} },
    { content: "test", type: "radio", onClick: function() {} }
  ]
};
export default PhoneGridSortButton;
