import React, { useState, useRef, useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import ListItem from "./ListItem";
import shortid from "shortid";
import { motion } from "framer-motion";

const variants = {
  open: {
    opacity: 1,
    height: 0,
    display: "block"
    // x: 0,
    // flip: true
  },
  closed: {
    opacity: 0,
    height: "auto",
    display: "none"
    // x: "-100vw",
    // flip: true
  }
};

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      position: "relative",
      zIndex: "10"
    },
    inputRoot: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000BF",
      borderRadius: "4px",
      opacity: "1",
      display: "flex",
      boxSizing: "border-box",
      padding: "12px",
      alignItems: "center",
      color: "#000000BF",
      "&:focus-within": {
        borderColor: ({ variant }) =>
          `${
            theme.palette[variant]
              ? theme.palette[variant].main
              : theme.palette.primary.main
          }`
        // color: ({ variant }) =>
        //   `${
        //     theme.palette[variant]
        //       ? theme.palette[variant].main
        //       : theme.palette.primary.main
        //   }`
      }
    },
    input: {
      // remove default styles
      borderWidth: "0px",
      border: "none",
      width: "100%",
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      letterSpacing: "0",
      color: "#000000",
      margin: "0px 12px 0px 12px",
      // opacity: "0.5",
      "&:focus": { outline: "none" }
    },
    label: {
      display: "none"
    }
  };
});

const locations = [
  "Atwood",
  "Bamberg",
  "Britton",
  "Drayton",
  "Gadshill",
  "Hampstead",
  "Hawkesville",
  "Hesson",
  "Linwood",
  "Lisbon",
  "Milverton",
  "Monkton",
  "Moorefield",
  "Nithburg",
  "Shakespeare",
  "Tavistock",
  "Wellesley",
  "Other areas"
];

function LocationInput(props) {
  const classes = useStyles(props);
  // const { locations } = props;
  const idNumber = shortid.generate();
  const labelID = `label-${idNumber}`;
  const inputID = `input-${idNumber}`;
  const listID = `input-${idNumber}`;
  const [isOpen, setOpen] = useState(false, { name: "inputOpen" });
  const [listFocused, setListFocused] = useState(false, {
    name: "listFocused"
  });

  const { onSelection } = props;

  const node = useRef(null);

  const cb = useCallback(
    function handler(e) {
      if (node.current && node.current.contains(e.target)) {
        if (node.current === e.target) setOpen(!isOpen);
        return;
      }
      setOpen(false);
    },
    [isOpen]
  );
  if (listFocused) {
    var b = 2;
  }
  b++;
  useEffect(
    function() {
      document.addEventListener("mousedown", cb, false);
      document.addEventListener("keypress", cb, false);
      return function() {
        document.removeEventListener("mousedown", cb, false);
        document.addEventListener("keypress", cb, false);
      };
    },
    [isOpen]
  );

  //console.log(props.value);

  return (
    <div className={classes.root} ref={node}>
      {/* Invisible label */}
      <label className={classes.label} id={labelID}>
        Location selection
      </label>
      {/* Input */}
      <div
        className={classes.inputRoot}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        id={inputID}
        aria-labelledby={`${labelID} ${inputID}`}
        onClick={function() {
          if (props.disabled) return;
          setOpen(true);
        }}
        onKeyDown={function(e) {
          if (props.disabled) return;
          switch (e.keyCode) {
            case 32:
              setOpen(!isOpen);
            default:
              break;
          }
        }}
        onBlur={function() {
          // console.log("blur");
          // if (!listFocused) setOpen(false);
        }}
      >
        <i className="fa fa-map-marker-alt" aria-hidden="true"></i>{" "}
        <input
          disabled={props.disabled}
          className={classes.input}
          type="text"
          placeholder="Location selection"
          name="usrnm"
          readOnly
          value={props.value}
        />
        <i className="fa fa-caret-down"></i>{" "}
      </div>
      {/* Options pane */}

      <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
        <ListItem
          id={listID}
          tabindex="-1"
          role="listbox"
          aria-labelledby={labelID}
        //   role="option"
          variant="white"
          items={locations.map(function(location) {
            return {
              content: location,
              onClick: function() {
                // setValue(location);
                setOpen(false);
                onSelection && onSelection(location);
              },
              onFocus: function() {
                setListFocused(true);
              },
              onBlur: function() {
                setListFocused(false);
              }
            };
          })}
        ></ListItem>
      </motion.div>
    </div>
  );
}

LocationInput.defaultProps = {
  locations: [],
  onSelection: function() {}
};
export default LocationInput;
