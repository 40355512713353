import React, { useState, useCallback, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import posed from "react-pose";
import RippleIcon from "components/RippleIcon";
import MobileNav from "./MobileNav";
import MobileSearch from "./MobileSearch";

const Nav = posed.nav({
  open: {},
  closed: {}
});
const RotateLi = posed.li({
  closed: { rotate: "0deg" },
  open: { rotate: "360deg" }
});

const AnimatedDiv = posed.div({
  closed: {
    height: "0px",
    opacity: 0,
    applyAtEnd: { oveflow: "hidden", display: "none" }
  },
  open: {
    height: "100vh",
    opacity: 1,
    applyAtStart: { oveflow: "hidden", display: "block" }
  }
});

const AnimatedSearchDiv = posed.div({
  closed: {
    height: "0px",
    opacity: 0,
    applyAtEnd: { oveflow: "hidden", display: "none" }
  },
  open: {
    height: "auto",
    opacity: 1,
    applyAtStart: { oveflow: "hidden", display: "block" }
  }
});
const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderBottom: "1px solid #CCCCCC",
      opacity: "1",
      padding: "0px 16px 0px 16px"
      // position: "relative"
      // zIndex: "100"
    },
    content: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& > *:last-child": {
        marginRight: 0
      }
    },
    logo: {
      display: "block",
      // height: "10px",
      width: 125,
      height: "auto",
      marginRight: "auto"
      // listStyleType: "none"
    },
    icon: {
      display: "block",
      padding: "25px 15px 25px 15px",
      width: 44,
      position: "relative"
    },
    navContainer: {
      position: "relative"
    }
  };
});

function Mobile(props) {
  const vals = {
    NAV: "NAV",
    SEARCH: "SEARCH",
    PHONE: "PHONE",
    LOC: "LOC",
    DEFAULT: ""
  };
  const classes = useStyles(props);
  const [value, setValue] = useState("");

  const root = useRef(null);
  const areas = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  const cb = useCallback(function handler(e) {
    if (root.current && root.current.contains(e.target)) {
      if (
        areas.every(area => {
          return !area.current.contains(e.target);
        })
      ) {
        setValue(vals.DEFAULT);
      }
      // alert("case");

      return;
    } else {
      setValue(vals.DEFAULT);
    }
  });

  useEffect(
    function() {
      document.addEventListener("click", cb, false);
      return function() {
        document.removeEventListener("click", cb, false);
      };
    },
    [value]
  );

  //console.log("Mobile", props);

  return (
    <Nav className={classes.root} ref={root}>
      <ul className={classes.content} role="menu">
        <li className={classes.logo} role="menuitem">
          <a href="/">
            <img
              src="/assets/images/mornington-colour.svg"
              alt="Mornington communications logo"
            />
          </a>
        </li>

        <li ref={areas[0]} role="menuitem">
          <RippleIcon>
            <a href="tel:+18002508750" className={classes.icon}>
              <i class="fa fa-phone"></i>
            </a>
          </RippleIcon>
        </li>

        <RippleIcon role="menuitem">
          <li ref={areas[1]}>
            <a
              href="/"
              className={classes.icon}
              onClick={function(e) {
                e.preventDefault();
                if (value === vals.LOC) {
                  setValue("");
                  return;
                }
                setValue(vals.LOC);
              }}
            >
              <i className="fa fa-location-arrow"></i>{" "}
            </a>
          </li>
        </RippleIcon>
        <RippleIcon role="menuitem">
          <li ref={areas[2]}>
            <a
              href="/"
              className={classes.icon}
              onClick={function(e) {
                e.preventDefault();
                if (value === "SEARCH") {
                  setValue("");
                  return;
                }
                setValue("SEARCH");
              }}
            >
              <i className="fa fa-search"></i>
            </a>
          </li>
        </RippleIcon>

        <RippleIcon role="menuitem">
          <RotateLi pose={value === "NAV" ? "open" : "closed"} ref={areas[3]}>
            <a
              href="#"
              className={classes.icon}
              onClick={function(e) {
                e.preventDefault();
                if (value === "NAV") {
                  setValue("");
                  return;
                }
                setValue("NAV");
              }}
            >
              {value === "NAV" ? (
                <i class="fa fa-times"> </i>
              ) : (
                <i class="fa fa-bars"> </i>
              )}
            </a>
          </RotateLi>
        </RippleIcon>
      </ul>

      <div ref={areas[4]}>
        <AnimatedDiv pose={value === vals.NAV ? "open" : "closed"}>
          <MobileNav />
        </AnimatedDiv>
        <AnimatedSearchDiv
          pose={value === vals.SEARCH || value === vals.LOC ? "open" : "closed"}
        >
          <MobileSearch
            location={props.location}
            setLocation={props.setLocation}
          />
        </AnimatedSearchDiv>
      </div>
    </Nav>
  );
}

export default Mobile;
