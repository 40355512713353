import React, { useState, useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Markdown from "components/Markdown";
import AriaModal from "react-aria-modal";

const useStyles = createUseStyles(function(theme) {
  return {
    root: {
      color: theme.palette.primary.main
    },
    modal: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #707070",
      opacity: "1",
      maxWidth: 814,
      width: "100vw",
      height: "auto",
      maxHeight: 600,
      overflow: "scroll",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: "32px",
      boxSizing: "border-box"
    },
    modalClose: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "white",
      borderStyle: "none"
    }
  };
});

function View(props) {
  const classes = useStyles(props);

  const history = useHistory();

  const hash = history.location.hash;

  const [open, setOpen] = useState(hash === "#modal" || false);
  const cb = useCallback(
    function(e) {
      if (e.newURL.includes("#modal")) {
        setOpen(true);
      }
    },
    [setOpen]
  );

  useEffect(function() {
    window.addEventListener("hashchange", cb);
    return function() {
      window.removeEventListener("hashchange", cb);
    };
  });
  return open ? (
    <AriaModal
      titleText="Test"
      applicationNode={function() {
        return document.getElementById("root");
      }}
      getApplicationNode={function() {
        document.getElementById("root");
      }}
      underlayClickExits={true}
      onExit={function() {
        // history.push(".");
        window.location.hash = "#content";

        setOpen(false);
      }}
    >
      <div className={classes.modal}>
        <button
          id="demo-one-deactivate"
          className={classes.modalClose}
          onClick={function() {
		//unset hash
	        history.goBack();
		  setOpen(false);
          }}
        >
          <i class="fa fa-times"></i>
          <span hidden> Close Modal</span>
        </button>
        <Markdown escapeHtml={false}>{props.children}</Markdown>
      </div>
    </AriaModal>
  ) : null;
}
export default View;
