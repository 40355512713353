import React, { useState, useRef, useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import shortid from "shortid";
import AutoComplete from "react-autocomplete";
const useStyles = createUseStyles(function (theme) {
  return {
    root: {
      width: "100%",
      position: "relative",
      zIndex: 999
    },

    inputRoot: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000BF",
      width: "100%",
      font: "400 16px/22px Avenir",
      borderRadius: "4px",
      opacity: "1",
      display: "flex",
      boxSizing: "border-box",
      padding: "12px",
      alignItems: "center",
      color: "#000000BF",
      "&:focus-within": {
        borderColor: ({ variant }) =>
          `${
          theme.palette[variant]
            ? theme.palette[variant].main
            : theme.palette.primary.main
          }`,
        outline: "none"
      }
    },
    input: {
      // remove default styles
      width: "100%",
      borderWidth: "0px",
      border: "none",
      // ui styles
      textAlign: "left",
      font: "400 16px/22px Avenir",
      // letterSpacing: "0",
      // color: "#000000",
      // margin: "0px 12px 0px 12px",
      // opacity: "0.5",
      paddingLeft: "10px",
      "&:focus": { outline: "none" }
    },
    icon: {
      color: ({ variant }) =>
        theme.palette[variant]
          ? theme.palette[variant].main
          : theme.palette.primary.main,

      fontSize: 8
    },
    label: {
      display: "none"
    },
    submenu: {
      backgroundColor: ({ variant }) =>
        `${
        theme.palette[variant]
          ? theme.palette[variant].contrast
          : theme.palette.primary.contrast
        }`,
      padding: 0,
      margin: 0,
      display: "block",
      position: "absolute",
      border: "1px solid #CCCCCC",
      borderRadius: "0px 0px 4px 4px",
      opacity: "1",
      minWidth: 161,
      width: "100%",
      transition: "0.3s"
    },
    li: {
      color: "#000000",
      listStyleType: "none",
      display: "block",
      boxSizing: "border-box",
      cursor: "pointer",
      // position: "relative",
      zIndex: "200"
    },
    a: {
      padding: "8px 16px 8px 16px",
      color: "#000000",
      textDecoration: "none",
      textAlign: "left",
      font: "500 0.875rem/1.0625rem Avenir",
      letterSpacing: "0",
      transition: "0.3s",
      opacity: 1,
      display: "block",
      "&:hover": {
        backgroundColor: ({ variant }) => `${theme.palette.gray.light}`,

        textDecoration: "none"
      },
      "&:focus  ": {
        boxShadow: `0 0 0 0.1rem ${theme.palette.gray.darker}`,
        // outline: "none",
        zIndex: 10
      },
      "&:active": {
        backgroundColor: `${theme.palette.gray.dark}`
      }
    },

    highlight: {
      backgroundColor: ({ variant }) => `${theme.palette.gray.light}`,
      // outline: "none",
      zIndex: 10
    }
  };
});

const locations = ["Afghanistan", "Albania", "Ukraine"];

function lcs(x, y) {
  x = x.toLowerCase();
  y = y.toLowerCase();
  var s,
    i,
    j,
    m,
    n,
    lcs = [],
    row = [],
    c = [],
    left,
    diag,
    latch;
  //make sure shorter string is the column string
  if (m < n) {
    s = x;
    x = y;
    y = s;
  }
  m = x.length;
  n = y.length;
  //build the c-table
  for (j = 0; j < n; row[j++] = 0);
  for (i = 0; i < m; i++) {
    c[i] = row = row.slice();
    for (diag = 0, j = 0; j < n; j++, diag = latch) {
      latch = row[j];
      if (x[i] == y[j]) {
        row[j] = diag + 1;
      } else {
        left = row[j - 1] || 0;
        if (left > row[j]) {
          row[j] = left;
        }
      }
    }
  }
  i--;
  j--;
  //row[j] now contains the length of the lcs
  //recover the lcs from the table
  while (i > -1 && j > -1) {
    switch (c[i][j]) {
      default:
        j--;
        lcs.unshift(x[i]);
      case i && c[i - 1][j]:
        i--;
        continue;
      case j && c[i][j - 1]:
        j--;
    }
  }
  return lcs.join("");
}
function getSubsequence(type, opt) {
  var i = opt.toLowerCase().indexOf(lcs(type, opt));
  var begin = opt.substring(0, i);
  var mid = opt.substring(i, i + type.length);
  var end = opt.substring(i + type.length, opt.length);

  return (
    <span>
      {begin}
      <strong>{mid}</strong>
      {end}
    </span>
  );
}

function ILI(props) {
  const classes = useStyles(props);
  //console.log("props", props);
  // const { locations } = props;
  const idNumber = shortid.generate();
  const labelID = `label-${idNumber}`;
  const inputID = `input-${idNumber}`;
  const listID = `input-${idNumber}`;
  const [value, setValue] = useState(props.value || "");
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false, { name: "inputOpen" });
  const [listFocused, setListFocused] = useState(false, {
    name: "listFocused"
  });

  const { onSelection, locations } = props;

  const node = useRef(null);

  return (
    <div className={classes.root} ref={node} style={props.style}>
      <AutoComplete
        getItemValue={item => item.label}
        selectOnBlur={true}
        items={locations}
        renderItem={(item, isHighlighted) => {
          return (
            <li
              className={`${classes.li} ${isHighlighted && classes.highlight}`}
              role="menuitem"
            >
              <span
                className={`${classes.a} ${classes.subMenuItem}`}
                style={{ transform: "capitalize" }}
              >
                {getSubsequence(value, item.label)}
              </span>
            </li>
          );
        }}
        shouldItemRender={function (item, value) {
          return item.label.toLowerCase().includes(value.toLowerCase());
        }}
        onMenuVisibilityChange={function (isOpen) {
          setMenuOpen(isOpen);
        }}
        // value={value}
        // Set the value of the of the input to be empty which forces the dropdown to have all locations every single time
        value=''
        onChange={e => {
          setValue(e.target.value);
          props.onChange && props.onChange(e.target.value);
        }}
        onSelect={val => {
          setValue(val);
          onSelection(val);
          {/* Set local storage item when location is selected */ }
          sessionStorage.setItem("location", val);
        }}
        inputProps={{
          className: `${classes.input}`,
          iconcode: props.iconcode
        }}
        wrapperProps={{ className: classes.root }}
        style={{ width: "100%" }}
        renderMenu={function (items, value, style) {
          return (
            <div
              style={{
                minWidth: "100%",
                maxHeight: "50vh",
                overflow: "scroll",
                overflowX: "hidden",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 12px",
                position: "absolute",
                backgroundColor: "white"
              }}
              children={items}
              role="menu"
            />
          );
        }}
        renderInput={function (props) {
          return (
            <div className={`${classes.inputRoot}`}>
              <i
                className={`fa ${props.iconcode || "fa-map-marker-alt"}`}
                aria-hidden="true"
              ></i>
              <input {...props} placeholder="Location selection" />
              {/* Make  icon clicable there by confirming with UI guidelies */}
              <div onClick={() => { menuOpen ? props.onBlur() : props.onFocus() }}>
                <i
                  className={`fa fa-caret-${menuOpen ? "up" : "down"}`}
                ></i>{" "}
              </div>

            </div>
          );
        }}
      />
      {/* Options pane */}
    </div>
  );
}

ILI.defaultProps = {
  locations: [],
  onSelection: function () { }
};
export default ILI;
